import {Modal} from 'react-bootstrap'

export const WaitSending = () => {
  return (
    <Modal show={true}>
      <Modal.Header>
        <Modal.Title>Please wait</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Sending...
      </Modal.Body>
    </Modal>
  )
}
