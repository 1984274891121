import PropTypes from 'prop-types'
import {Modal, Button} from 'react-bootstrap'

export const AppResponse = ({responseText, onReady}) => {
  return (
    <Modal show={true}>
      <Modal.Header closeButton>
        <Modal.Title>App response</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {responseText}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={onReady}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

AppResponse.propTypes = {
  responseText: PropTypes.string.isRequired,
  onReady: PropTypes.func.isRequired,
}
