import React from "react";
import {Card, Table, InputGroup, FormControl, Nav} from 'react-bootstrap';
import PropTypes from 'prop-types'
import {Machine, assign} from 'xstate'
import {useMachine} from '@xstate/react'
import {server} from '../server'
import Badge from '../components/Badge'
import './UnitListPage.css'

const STATE = {
  LOADING: 'LOADING',
  LISTING: 'LISTING',
  EDITING: 'EDITING',
  UPDATING: 'UPDATING',
}

const EVENT = {
  LOADED: 'LOADED',
  EDIT_START: 'EDIT_START',
  EDIT_READY: 'EDIT_READY',
  UPDATE_READY: 'UPDATE_READY',
  CANCEL: 'CANCEL',
}

const statechart = {
  id: 'imageManagerSM',
  initial: STATE.LOADING,
  context: {
    userId: null,
    unitInfoList: [],
    unitId: null,
    unitField: null,
  },
  states: {
    [STATE.LOADING]: {
      invoke: {
        src: 'loadUnits',
        onDone: STATE.LISTING,
      },
      exit: ['refreshUnits']
    },
    [STATE.LISTING]: {
      on: {
        [EVENT.EDIT_START]: STATE.EDITING,
      },
    },
    [STATE.EDITING]: {
      entry: ['onEditingEntry'],
      on: {
        [EVENT.EDIT_READY]: STATE.UPDATING,
      },
    },
    [STATE.UPDATING]: {
      invoke: {
        src: 'updateUnit',
        onDone: STATE.LOADING,
      },
    },
  },
}

const statechartOptions = {
  services: {
    loadUnits: async(context) => {
      return await server.getUnits(context.userId)
    },
    updateUnit: async(context, event) => {
      return await server.updateUnit(event.unitId, event.field, event.value)
    },
  },
  actions: {
    refreshUnits: assign((context, event) => {
      return {
        unitInfoList: event.data,
      }
    }),
    onEditingEntry: assign((context, event) => {
      return {
        unitId: event.unitId,
        unitField: event.unitField,
      }
    }),
  },
}

export const UnitListPage = ({userId}) => {
  statechart.context.userId = userId
  const [state, send, service] = useMachine(Machine(statechart, statechartOptions))
  const [filter, setFilter] = React.useState('')

  //React.useEffect(() => service.subscribe(state => console.log(state)).unsubscribe, [service])
  const unitInputRef = React.useRef()

  const filterUnitInfoList = () => {
    return unitInfo => unitInfo.unit.name.match(new RegExp(filter, 'i')) ||
      unitInfo.unit.location.match(new RegExp(filter, 'i')) ||
      unitInfo.unit.area.match(new RegExp(filter, 'i')) ||
      unitInfo.unit.id.toString().match(new RegExp(filter, 'i'))
  }

  return (
    <Card id="rtm-page_UnitList" className="rtm-page">
      <Card.Body>
        <Card.Title>Units List</Card.Title>
        <InputGroup className="mb-3">
          <InputGroup.Text id="rtm_unit-search-input">
            <i className="fas fa-search"></i>
          </InputGroup.Text>
          <FormControl
            placeholder="Filter Unit name, Location or Area..."
            aria-describedby="rtm_unit-search-input"
            value={filter}
            onChange={e => setFilter(e.target.value)}
          />
        </InputGroup>
        <iframe src={process.env.REACT_APP_GF_ALMS}></iframe>
        {(state.matches(STATE.LISTING) || state.matches(STATE.EDITING)) && (
          <Table id="rtm_units-table" striped bordered hover>
            <thead>
            <tr>
              <th>#</th>
              <th>Unit name</th>
              <th className="rtm_location-column">Location</th>
              <th>Area</th>
              <th>PV Power</th>
              <th>Battery Voltage</th>
              <th>Health Status</th>
            </tr>
            </thead>
            <tbody>
            {state.context.unitInfoList
              .filter(filterUnitInfoList())
              .map(unitInfo => {
                const badge = {
                  variant: 'error',
                  text: '?',
                }

                if(unitInfo.isOnline) {
                  if(unitInfo.hasError) {
                    badge.variant = 'error'
                    badge.text = 'Error'
                  }
                  else {
                    badge.variant = 'success'
                    badge.text = 'OK'
                  }
                }
                else {
                  badge.variant = 'warning'
                  badge.text = 'Down'
                }

                return (
                  <tr key={unitInfo.unit.id}>
                    <td className="rtm_unit-list-id">
                      <Nav.Link href={`/unit/${unitInfo.unit.id}`}>
                        {unitInfo.unit.id}
                        <i className="fas fa-external-link-alt"></i>
                      </Nav.Link>
                    </td>
                    {/*TODO: refactor editable cells to DRY!*/}
                    <td
                      onClick={() => send(EVENT.EDIT_START, {
                        unitId: unitInfo.unit.id,
                        unitField: 'name'
                      })}
                    >
                      {(state.matches(STATE.EDITING) && state.context.unitId === unitInfo.unit.id && state.context.unitField === 'name') ?
                        <input
                          ref={unitInputRef}
                          type="text"
                          defaultValue={unitInfo.unit.name}
                          onKeyDown={e => {
                            if(e.key === 'Enter' || e.key === 'Tab'){
                              send(EVENT.EDIT_READY, {
                                unitId: unitInfo.unit.id,
                                field: 'name',
                                value: unitInputRef.current.value,
                              })
                            }
                          }}
                        /> :
                        unitInfo.unit.name
                      }
                    </td>
                    <td
                      onClick={() => send(EVENT.EDIT_START, {
                        unitId: unitInfo.unit.id,
                        unitField: 'location',
                      })}
                    >
                      {(state.matches(STATE.EDITING) && state.context.unitId === unitInfo.unit.id && state.context.unitField === 'location') ?
                        <input
                          ref={unitInputRef}
                          type="text"
                          defaultValue={unitInfo.unit.location}
                          onKeyDown={e => {
                            if(e.key === 'Enter' || e.key === 'Tab'){
                              send(EVENT.EDIT_READY, {
                                unitId: unitInfo.unit.id,
                                field: 'location',
                                value: unitInputRef.current.value,
                              })
                            }
                          }}
                        /> :
                        unitInfo.unit.location
                      }
                    </td>
                    <td
                      onClick={() => send(EVENT.EDIT_START, {
                        unitId: unitInfo.unit.id,
                        unitField: 'area',
                      })}
                    >
                      {(state.matches(STATE.EDITING) && state.context.unitId === unitInfo.unit.id && state.context.unitField === 'area') ?
                        <input
                          ref={unitInputRef}
                          type="text"
                          defaultValue={unitInfo.unit.area}
                          onKeyDown={e => {
                            if(e.key === 'Enter' || e.key === 'Tab'){
                              send(EVENT.EDIT_READY, {
                                unitId: unitInfo.unit.id,
                                field: 'area',
                                value: unitInputRef.current.value,
                              })
                            }
                          }}
                        /> :
                        unitInfo.unit.area
                      }
                    </td>
                    <td>{unitInfo.pvPower}</td>
                    <td>{unitInfo.batteryVoltage}</td>
                    <td className="rtm_healthBadge">
                      <Badge
                        text={badge.text}
                        variant={badge.variant}
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        )}
        {state.matches(STATE.LOADING) && <div>Loading...</div>}
      </Card.Body>
    </Card>
  )
}

UnitListPage.propTypes = {
  userId: PropTypes.string.isRequired,
}
