import PropTypes from 'prop-types'
import {Nav, Navbar, NavDropdown, Tooltip, OverlayTrigger} from 'react-bootstrap'
import './Header.css'

export const Header = ({username, onLogout, appServices, onAppStart}) => {
  let brand = 'Remote Monitoring'
  let logoSrc = ''

  // Temporarily changed branding strategy
  /*if(process.env.REACT_APP_VARIANT === 'arizon') {
    brand = 'Arizon Remote Monitoring'
    logoSrc = '/img/arizon_logo_2.png'
  }
  else if(process.env.REACT_APP_VARIANT === 'minealytics') {
    brand = 'Minealytics IoT Platform'
    logoSrc = '/img/minealytics_logo.svg'
  }*/

  if(/minealytics/.test(username)) {
    brand = 'Minealytics IoT Platform'
    logoSrc = '/img/minealytics_logo.svg'
  }
  else {
    brand = 'Arizon Remote Monitoring'
    logoSrc = '/img/arizon_logo_2.png'
  }

  const handleSelect = (eventKey) => console.log(`selected ${eventKey}`);

  let navTitle

  if(/^\/$/.test(window.location.pathname) || /^\/units-list/.test(window.location.pathname)) {
    navTitle = (
      <span>
          <i className="fas fa-list"></i>
          <span>Units List</span>
        </span>
    )
  }
  else if(/^\/unit.*/.test(window.location.pathname)) {
    navTitle = (
      <span>
          <i className="fas fa-solar-panel"></i>
          <span>Unit</span>
        </span>
    )
  }
  else if(/^\/statistics/.test(window.location.pathname)) {
    navTitle = (
      <span>
          <i className="fas fa-chart-area"></i>
          <span>Statistics</span>
        </span>
    )
  }
  else if(/^\/diagnostics/.test(window.location.pathname)) {
    navTitle = (
      <span>
          <i className="fas fa-stethoscope"></i>
          <span>Diagnostics</span>
        </span>
    )
  }
  else if(/^\/uptime/.test(window.location.pathname)) {
    navTitle = (
      <span>
          <i className="fas fa-clock"></i>
          <span>Uptime</span>
        </span>
    )
  }
  else {
    console.error(`Unknown pathname: ${window.location.pathname}`)
  }

  const userTitle = (
    <span>
      <i className="far fa-user"></i>
      <span>{username}</span>
    </span>
  )


  return (
    <Navbar className="justify-content-end" id="rtm_header" bg="dark" variant="dark">

      <Nav onSelect={handleSelect}>
        <Nav.Item id="rtm_header-brand" href="/units-list">
          <Navbar.Brand href="/units-list">
            <img src={logoSrc} alt={brand} />
          </Navbar.Brand>
          <Navbar.Brand id="rtm_header-brand_text" href="/units-list">{brand}</Navbar.Brand>
        </Nav.Item>

        <NavDropdown title={(
          <span>
            <i className="fas fa-desktop"></i>
            <span>Apps</span>
          </span>
        )}>
          {appServices.map(appService => (
            <OverlayTrigger
              key={appService.id}
              placement="left"
              overlay={
                <Tooltip id={`tooltip-${appService.id}`}>
                  {appService.appDescription}
                </Tooltip>
              }
            >
              <NavDropdown.Item
                onClick={() => onAppStart(appService.id)}
              >
                {appService.appName}
              </NavDropdown.Item>
            </OverlayTrigger>
          ))}
        </NavDropdown>

        <NavDropdown align="end" title={navTitle}>
          <NavDropdown.Item href="/units-list">
            <i className="fas fa-list"></i>
            Units List
          </NavDropdown.Item>
          <NavDropdown.Item href="/unit">
            <i className="fas fa-solar-panel"></i>
            Unit
          </NavDropdown.Item>
          <NavDropdown.Item href="/statistics">
            <i className="fas fa-chart-area"></i>
            Statistics
          </NavDropdown.Item>
          <NavDropdown.Item href="/diagnostics">
            <i className="fas fa-stethoscope"></i>
            Diagnostics
          </NavDropdown.Item>
          <NavDropdown.Item href="/uptime">
            <i className="fas fa-clock"></i>
            Uptime
          </NavDropdown.Item>
        </NavDropdown>

        <NavDropdown id="rtm_header-user" title={userTitle}>
          <NavDropdown.Item>
            <i className="fas fa-cog"></i>
            Settings
          </NavDropdown.Item>
          <NavDropdown.Item
            id="rtm_header-logout"
            onClick={onLogout}
          >
            <i className="fas fa-sign-out-alt"></i>
            Log out
          </NavDropdown.Item>
        </NavDropdown>

      </Nav>
    </Navbar>
  )

}
Header.propTypes = {
  username: PropTypes.string.isRequired,
  appServices: PropTypes.array.isRequired,
  onLogout: PropTypes.func.isRequired,
  onAppStart: PropTypes.func.isRequired,
}
