import React from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import {createMachine, assign} from 'xstate'
import {useMachine} from '@xstate/react'
import {server} from './server'
import {UnitListPage} from './pages/UnitListPage'
import {UnitPage} from './pages/UnitPage'
import {StatisticsPage} from './pages/StatisticsPage'
import {DiagnosticsPage} from './pages/DiagnosticsPage'
import {UptimePage} from './pages/UptimePage'
import Header from './components/Header'
import {UnitSelection} from './components/AppServices/UnitSelection'
import {UserInputs} from './components/AppServices/UserInputs'
import {AppResponse} from './components/AppServices/AppResponse'
import {WaitSending} from './components/AppServices/WaitSending'
import './App.css'

const STATES = {
  READY: 'READY',
  FETCHING_INPUTS: 'FETCHING_INPUTS',
  UNIT_SELECTION: 'UNIT_SELECTION',
  USER_INPUTS: 'USER_INPUTS',
  SENDING: 'SENDING',
  SHOWING_RESPONSE: 'SHOWING_RESPONSE',
}

const EVENTS = {
  START_SERVICE_APP: 'START_SERVICE_APP',
  SUCCESS: 'SUCCESS',
  CANCEL: 'CANCEL',
  GET_USER_INPUTS: 'GET_USER_INPUTS',
  SEND: 'SEND',
  FINISH: 'FINISH',
}

const appServiceMachine = createMachine({
  id: 'AppServiceMachine',
  initial: STATES.READY,
  context: {
    appServiceId: null,
    unitInfoList: [],
    selectedUnits: [],
    response: null,
  },
  states: {

    [STATES.READY]: {
      on: {
        [EVENTS.START_SERVICE_APP]: STATES.FETCHING_INPUTS,
      },
      exit: 'setAppServiceId',
    },

    [STATES.FETCHING_INPUTS]: {
      invoke: {
        src: 'fetchUnits',
        onDone: STATES.UNIT_SELECTION,
      },
      exit: 'fetchingUnitsSuccess',
    },

    [STATES.UNIT_SELECTION]: {
      on: {
        [EVENTS.GET_USER_INPUTS]: STATES.USER_INPUTS,
        [EVENTS.SEND]: STATES.SENDING,
        [EVENTS.CANCEL]: {
          target: STATES.READY,
          actions: ['cancel'],
        },
      },
      exit: 'setSelectedUnits',
    },

    [STATES.USER_INPUTS]: {
      on: {
        [EVENTS.CANCEL]: {
          target: STATES.READY,
          actions: ['cancel'],
        },
        [EVENTS.SEND]: STATES.SENDING,
      },
    },

    [STATES.SENDING]: {
      invoke: {
        src: 'send',
        onDone: STATES.SHOWING_RESPONSE,
      },
      exit: 'postAppSuccess',
    },

    [STATES.SHOWING_RESPONSE]: {
      on: {
        [EVENTS.SUCCESS]: STATES.READY,
      },
    },
  },
})

function App() {
  const auth0 = useAuth0()
  const [appServices, setAppServices] = React.useState([])
  const appServiceMachineOptions = {
    actions: {
      setAppServiceId: assign((context, event) => ({appServiceId: event.appId})),
      fetchingUnitsSuccess: assign((context, event) => ({unitInfoList: event.data})),
      postAppSuccess: assign((context, event) => {
        return {response: event.data}
      }),
      cancel: assign(() => ({
        appServiceId: null,
        unitInfoList: [],
        selectedUnits: [],
      })),
      setSelectedUnits: assign((context, event) => ({selectedUnits: event.selectedUnits}))
    },
    services: {
      fetchUnits: async() => await server.getUnits(auth0.user.name),
      send: async(context, event) => {
        const app = appServices.find(a => a.id === context.appServiceId)

        const response = await server.postAppService(auth0.user.name, {
          appId: app.id,
          endpoint: app.endpoint,
          units: context.selectedUnits,
          userInputs: event.userInputs,
        })

        return response
      }
    },
  }
  const [state, send, service] = useMachine(appServiceMachine, appServiceMachineOptions);

  React.useEffect(async() => {
    if(auth0.isLoading || !auth0.isAuthenticated) {
      return
    }
    const token = await auth0.getAccessTokenSilently()

    const appServicesResult = await server.getAppServices(auth0.user.name)

    localStorage.setItem('bearer-token', token)
    localStorage.setItem('username', auth0.user.name)

    setAppServices(appServicesResult)
  }, [auth0])

  //React.useEffect(() => service.subscribe(state => console.log(state)).unsubscribe, [service])

  // region authentication
  if(auth0.isLoading) {
    return <h3 style={{color: 'white', background: 'black', padding: 16}}>Authenticating, please wait...</h3>
  }

  if(!auth0.isAuthenticated) {
    auth0.loginWithRedirect()
  }
  // endregion

  const onLogout = () => {
    if(window.confirm('Are you sure to log out?')) {
      localStorage.clear()
      auth0.logout({returnTo: window.location.origin})
    }
  }

  const onAppStart = appId => {
    send(EVENTS.START_SERVICE_APP, {appId})
  }

  const onUnitSelectionReady = selectedUnits => {
    if(appServices.find(a => a.id === state.context.appServiceId).userInputs) {
      send(EVENTS.GET_USER_INPUTS, {selectedUnits})
    }
    else {
      send(EVENTS.SEND, {selectedUnits})
    }
  }

  const onUserInputReady = userInputs => {
    send(EVENTS.SEND, {userInputs})
  }

  const onAppResponseReady = () => send(EVENTS.SUCCESS)

  const onCancel = () => send(EVENTS.CANCEL)

  return (
    <Router>

      {state.matches(STATES.UNIT_SELECTION) && (
        <UnitSelection
          appName={appServices.find(a => a.id === state.context.appServiceId).appName}
          unitInfoList={state.context.unitInfoList}
          onReady={onUnitSelectionReady}
          onCancel={onCancel}
        />
      )}

      {state.matches(STATES.USER_INPUTS) && (
        <UserInputs
          appService={appServices.find(a => a.id === state.context.appServiceId)}
          onReady={onUserInputReady}
          onCancel={onCancel}
        />
      )}

      {state.matches(STATES.SENDING) && (
        <WaitSending/>
      )}

      {state.matches(STATES.SHOWING_RESPONSE) && (
        <AppResponse
          responseText={state.context.response}
          onReady={onAppResponseReady}
        />
      )}

      <Header
        username={auth0.user.name}
        appServices={appServices}
        onLogout={onLogout}
        onAppStart={onAppStart}
      />

      <Switch>
        <Route path="/units-list">
          <UnitListPage userId={auth0.user.name}/>
        </Route>
        <Route path="/unit/:unitId">
          <UnitPage />
        </Route>
        <Route path="/unit">
          <UnitPage />
        </Route>
        <Route path="/statistics">
          <StatisticsPage />
        </Route>
        <Route path="/diagnostics">
          <DiagnosticsPage />
        </Route>
        <Route path="/uptime">
          <UptimePage />
        </Route>
        <Route exact path="/">
          {auth0.isAuthenticated ? <Redirect to="/units-list" /> : null}
        </Route>
      </Switch>

    </Router>
  )
}

App.displayName = 'RTM-App'

export default App
