import {Fragment, useState} from 'react'
import PropTypes from 'prop-types'
import {Modal, Button} from 'react-bootstrap'
import Badge from '../../components/Badge'
import './UnitSelection.css'

export const UnitSelection = ({
  appName,
  unitInfoList,
  onReady,
  onCancel,
}) => {
  const [filter, setFilter] = useState('')
  const [selectAll, setSelectAll] = useState(false)
  const checkboxRefList = []
  const filteredUnits = unitInfoList.filter(unitInfo => {
    try{
      const nameMatch = unitInfo.unit.name.match(new RegExp(filter, 'i'))
      const locationMatch = unitInfo.unit.location.match(new RegExp(filter, 'i'))
      const areaMatch = unitInfo.unit.area.match(new RegExp(filter, 'i'))

      return nameMatch || locationMatch || areaMatch
    }
    catch(ex){
      console.log(ex)
    }

    return false
  })

  return (
    <Modal
      show={true}
      onHide={onCancel}
    >
      <Modal.Header closeButton>
        <Modal.Title>Select units</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="startingApp">Starting App: {appName}</span>
        <div>
          <input
            type="text"
            value={filter}
            onChange={e => setFilter(e.target.value)}
            placeholder="filter (RegExp)"
          />
        </div>
        {/*<div>
          <input
            type="checkbox"
            checked={selectAll}
            onChange={e => setSelectAll(e.target.checked)}
          />
          select all
        </div>
        <div>select all: {selectAll ? 'yes' : 'no'}</div>*/}
        <div className="unitInfoList">
          {filteredUnits.map((unitInfo, unitInfoIdx) => {
            return (
              <Fragment key={unitInfo.unit.id}>
                <div>
                  <input
                    type="checkbox"
                    ref={checkboxRef => checkboxRefList[unitInfoIdx] = checkboxRef}
                    data-unit-id={unitInfo.unit.id}
                  />
                </div>
                <div className="unitInfoName">{unitInfo.unit.name}</div>
                <div>{unitInfo.unit.location}</div>
                <div>{unitInfo.unit.area}</div>
                <div>
                  {unitInfo.hasError ? <Badge text="has error" variant="warning">has error</Badge> : <Badge text="OK" variant="success" />}
                </div>
                <div>
                  {unitInfo.isOnline ? <Badge text="online" variant="success" /> : <Badge text="offline" variant="warning" />}
                </div>
              </Fragment>
            )
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => onReady(checkboxRefList.filter(c => c.checked).map(c => c.dataset.unitId))}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  )

}
UnitSelection.propTypes = {
  appName: PropTypes.string.isRequired,
  unitInfoList: PropTypes.array.isRequired,
  onReady: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}
