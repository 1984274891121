import {Fragment, createRef} from 'react'
import PropTypes from 'prop-types'
import {Modal, Button} from 'react-bootstrap'
import './UserInputs.css'

export const UserInputs = ({
  appService,
  onReady,
  onCancel,
}) => {
  const inputs = []

  return (
    <Modal
      show={true}
      onHide={onCancel}
    >
      <Modal.Header closeButton>
        <Modal.Title>User inputs</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Starting App: {appService.appName}</span>
        <div className="userInputs">
          {appService.userInputs.map(userInput => {
            const ref = createRef()
            inputs.push(ref)

            return (
              <Fragment key={userInput}>
                <span>{userInput}</span>
                <div>
                  <input
                    type="text"
                    ref={ref}
                    data-key={userInput}
                  />
                </div>
              </Fragment>
            )
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => onReady(inputs.map(i => ({key: i.current.dataset.key, value: i.current.value})))}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

UserInputs.propTypes = {
  appService: PropTypes.object.isRequired,
  onReady: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}
