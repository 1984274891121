import PropTypes from 'prop-types'
import './Badge.css'

export const Badge = ({text, variant}) => {
  let background = 'blue'

  switch (variant){
    case 'success':
      background = 'green'
      break

    case 'warning':
      background = 'orange'
      break

    case 'error':
      background = 'red'
      break

    case 'info':
      background = 'blue'
      break

    default:
      background = 'blue'
  }

  return (
    <span
      className="rtm-Badge"
      style={{background}}
    >{text}</span>
  )
}

Badge.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info'])
}

Badge.defaultProps = {
  variant: 'success',
}
