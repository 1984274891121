import PropTypes from 'prop-types'
import {useParams} from 'react-router-dom'

export const UnitPage = () => {
  const {unitId} = useParams()

  return (
    <div id="rtm-page_Unit" className="rtm-page fullHeightPage">
      <iframe id="rtm_grafana_iframe" title="Grafana" src={process.env.REACT_APP_GF_UNIT + `${unitId}`}></iframe>
    </div>
  )
}

UnitPage.propTypes = {
  selectedUnit: PropTypes.string,
}
